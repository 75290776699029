<template>
  <div v-loading="loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/pipeline' }">管线列表</el-breadcrumb-item>
        <el-breadcrumb-item>创建管线</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <div class="form-title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="管段名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管段编号" prop="materialsCode">
              <el-input v-model="form.materialsCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="管段长度" prop="model">
              <el-input v-model="form.model"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="敷设方式">
              <el-input v-model="form.specification"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="描述">
              <el-input v-model="form.factory"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="form-title">添加检查节点</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item v-for="(item, index) in checkNodes" :key="index" label="检查节点">
              <el-select v-model="checkNodes[index].patrolIds" placeholder="请选择">
                <el-option label="无" value=""></el-option>
                <el-option 
                  v-for="pItem in patrolList" 
                  :key="pItem.id" 
                  :label="pItem.patrolName" 
                  :value="pItem.id"></el-option>
              </el-select>
              <i @click="deleteNode(index)" v-if="index != 0" class="el-icon-close"></i>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="add-checkContent">
          <span @click="addCheckNode">
            <i class="el-icon-plus"></i>添加检查节点
          </span>
        </div>
        
        <div class="form-title">其他检查内容</div>
        <el-form-item v-for="(item, index) in checkContents" :key="index" label="检查内容">
          <el-input v-model="checkContents[index]" type="textarea" :rows="2" style="width:700px"></el-input>
          <i @click="deleteDate(index)" v-if="index != 0" class="el-icon-close"></i>
        </el-form-item>
        <div class="add-checkContent">
          <span @click="addCheckContent">
            <i class="el-icon-plus"></i>添加检查内容
          </span>
        </div>

        <div class="bottom-btn">
          <el-button type="primary" size="small" @click="$router.push('/pipeline')">取消</el-button>
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        materialsCode:[{ required: true, message: '必填项', trigger: 'blur' }],
        specification:[{ required: true, message: '必填项', trigger: 'blur' }],
        factory:[{ required: true, message: '必填项', trigger: 'blur' }],
        count:[{ required: true, message: '必填项', trigger: 'blur' }],
        model:[{ required: true, message: '必填项', trigger: 'blur' }],
        deviceTypeId:[{ required: true, message: '必填项', trigger: 'blur' }],
        staffId:[{ required: true, message: '必填项', trigger: 'blur' }],
        departmentId:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      patrolList: [],
      checkNodes: [{patrolIds:''}],
      checkContents: [""]
    };
  },
  methods: {
    addCheckNode() {
      this.checkNodes.push({patrolIds:''});
    },
    deleteNode(i) {
      this.checkNodes.splice(i, 1)
    },
    addCheckContent() {
      this.checkContents.push("");
    },
    deleteDate(i) {
      this.checkContents.splice(i, 1)
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("materialsCode", this.form.materialsCode);
          formData.append("model", this.form.model);
          formData.append("specification", this.form.specification);
          formData.append("factory", this.form.factory);
          formData.append("count", this.form.count);
          formData.append("deviceTypeId", this.form.deviceTypeId);
          // formData.append("facilityId", this.form.facilityId);
          formData.append("departmentId", this.form.departmentId);
          formData.append("staffId", this.form.staffId);
          formData.append("deviceStatusId", this.form.deviceStatusId);

          this.checkContents.forEach((item, index) => {
            let checkContentList = `checkContentList[${index}].checkContent`;
            formData.append(checkContentList, item);
          });

          this.$ajax.post("deviceCreate", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/deviceList");
          }).catch(err=>{
            this.loading = false;
          })
        }
      })
    },
    loadPatrolList() {
      this.$ajax.post("patrolList", {
        facilityId: ''
      }).then((res) => {
        this.patrolList = res.data;
      });
    },
  },
  mounted() {
    this.loadPatrolList()
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
</style>